import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getBookingDetails = createAsyncThunk('GET BOOKING DETAILS', async (data, thunkAPI) => {
  const response = await axios.get(`${URL.GET_BOOKING_DETAILS}/${data.referenceNumber}`, thunkAPI);
  return response.data;
});

export const updateBookingCommission = createAsyncThunk('UPDATE BOOKING COMMISSION', async (data, thunkAPI) => {
  const response = await axios.post(`${URL.GET_BOOKING_DETAILS}`, data, thunkAPI);
  return response.data;
});


const initialState = {
  isLoading: false,
  bookingDetails: null,
  adminSuccess: false,
  adminDetails: null,
};

export const bookingDetailsSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setBookingDetails: (state, action) => {
      state.bookingDetails = null;
    }
  },
  extraReducers: {
    // get admins
    [getBookingDetails.pending]: (state) => {
      state.isLoading = true;
      state.bookingDetails = null;
    },
    [getBookingDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.bookingDetails = action.payload.data;
    },
    [getBookingDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.bookingDetails = null;
      toast.error(action?.payload?.message);
    }
  },
});
export const {
  setBookingDetails,
} = bookingDetailsSlice.actions;

export default bookingDetailsSlice.reducer;
