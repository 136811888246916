import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'utils/axios';
import URL from 'config/url';

export const getCSVData = createAsyncThunk('GET CSV DATA', async (data, thunkAPI) => {
  let query = `?page=${data?.page}&pageSize=${data?.pageSize}&limit=${data?.limit}&text=${data?.text}&bookingStatus=${data?.bookingStatus}&provider=${data?.provider}&refundability=${data.refundability}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&startDate=${data?.startDate}&endDate=${data?.endDate}&category=${data?.category}&sorting=${data?.sorting}&userType=${data?.userType}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data?.agencyName}`;
  if (data?.agencyId) {
    query = `?agencyId=${data?.agencyId}&page=${data?.page}&pageSize=${data?.pageSize}&limit=${data?.limit}&text=${data?.text}&bookingStatus=${data?.bookingStatus}&provider=${data?.provider}&refundability=${data?.refundability}&currency=${data.currency}&paymentGateway=${data.paymentGateway}&startDate=${data?.startDate}&endDate=${data?.endDate}&category=${data?.category}&sorting=${data?.sorting}&userType=${data?.userType}&travelStartDate=${data.travelStartDate}&travelEndDate=${data.travelEndDate}&agencyName=${data?.agencyName}`;
  }
  const response = await axios.get(`${URL.CSV_DOWNLOAD}${query}`, thunkAPI);
  return response.data;
});

export const getAgencyCSVData = createAsyncThunk('GET AGENCY CSV DATA', async (data, thunkAPI) => {
  const query = `?text=${data?.text}&startDate=${data?.startDate}&endDate=${data?.endDate}&planType=${data?.planType}&subscriptionStatus=${data?.subscriptionStatus}&sortField=${data?.sortField}&sortOrder=${data?.sortOrder}&subscriptionDuration=${data?.subscriptionDuration}&paymentStatus=${data?.paymentStatus}&agencyStatus=${data?.agencyStatus}&originCountry=${data?.originCountry}&paymentGateway=${data?.paymentGateway}&hotelVendor=${data?.hotelVendor}&isSSOEnabled=${data?.isSSOEnabled}`;

  const response = await axios.get(`${URL.AGENCY_CSV_DOWNLOAD}${query}`, thunkAPI);
  return response.data;
});

export const getSearchesCSVData = createAsyncThunk('GET SEARCHES CSV DATA', async (data, thunkAPI) => {
  const query = `?text=${data?.text}&startDate=${data?.startDate}&endDate=${data?.endDate}`;

  const response = await axios.get(`${URL.SEARCHES_CSV_DOWNLOAD}${query}`, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  csvData: null,
};

export const CSVSlice = createSlice({
  name: 'csv',
  initialState,
  reducers: {},
  extraReducers: {
    // get csv data
    [getCSVData.pending]: (state) => {
      state.isLoading = true;
      state.csvData = null;
    },
    [getCSVData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.csvData = action.payload.data;
    },
    [getCSVData.rejected]: (state, action) => {
      state.isLoading = false;
      state.csvData = null;
      toast.error(action?.payload?.message);
    },

    // get agency csv data
    [getAgencyCSVData.pending]: (state) => {
      state.isLoading = true;
      state.csvData = null;
    },
    [getAgencyCSVData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.csvData = action.payload.data;
    },
    [getAgencyCSVData.rejected]: (state, action) => {
      state.isLoading = false;
      state.csvData = null;
      toast.error(action?.payload?.message);
    },

    // get searches csv data
    [getSearchesCSVData.pending]: (state) => {
      state.isLoading = true;
      state.csvData = null;
    },
    [getSearchesCSVData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.csvData = action.payload.data;
    },
    [getSearchesCSVData.rejected]: (state, action) => {
      state.isLoading = false;
      state.csvData = null;
      toast.error(action?.payload?.message);
    },
  },
});

export default CSVSlice.reducer;
